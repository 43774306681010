export const ROUTE_URLS = {
  PAGE_LAYOUT: "/",
  SIGN_IN: "/signin",
  SIGN_UP: "/signup",
  FORGOTTEN_PASSWORD: "/forgottenpassword",
  RESET_PASSWORD: "/reset/:uidb64/:token",
  ACCOUNT_ACTIVATION: "/activate/:uidb64/:token",
  PRODUCTS: "/:productsPage",
  PRODUCTDETAIL: "/product/:productId",
  CHECKOUT: "/checkout",
  CART: "/cart",
  SEARCH: "/search",
  DASHBOARD: "/dashboard",
  PROFILE: "/dashboard/profile",
  CHANGE_PASSWORD: "/dashboard/changepassword",
  ESHOP_USERS: "/dashboard/users",
  INDEX: "/dashboard/index",
  LAYOUT_PRODUCTS: "/#products",
  LAYOUT_CONTACT: "/#contact",
  LAYOUT_ABOUT_US: "/#aboutus",
  VERIFY: "/verify",
  COOKIE_POLICY: "/cookie-policy",
  PRODUCTS_CATEGORIES: "/products",
  DATA_PRIVACY: "/data-privacy",
};

export const META_PIXEL_ID = "510556795074024";

export const CONVERSION_API_TOKEN =
  "EAA5q9tvmNdEBO9fDgUucbQGiVgd54KY2gg7O3jDh7P1RZAvpsTAkWuMoQZCJoHlnMb8Aqv4fZCZAl0sH2J6mOr89GeGtOWK5IPrFK42p0CJ3hf82P0CcX1Mt8ux6zz6zH0VeutMdoXWTn3k1jYHhYZCJNVGY8DVt2XT58WWNuJhEwZCASEZAqFMB7DLN2BHGFXk7jbbPAZDZD";

export const isTestEnviromnent = false;

function createDEV_URL() {
  if (isTestEnviromnent) {
    return {
      PROD_URL: "http://192.168.47.101:3000",
      PROD_AUTH: "http://192.168.47.101:8000",
      PROD_ERP: "http://192.168.47.101:8881/Data",
      PROD_IMG: "http://192.168.47.101",
      PROD_BANK: "https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/cardpay",
      PROD_INDEX: "http://192.168.47.101:7700",
      CONVERSION_API_URL:
        "https://graph.facebook.com/v20.0/" +
        META_PIXEL_ID +
        "/events?access_token=" +
        CONVERSION_API_TOKEN,
    };
  } else {
    return {
      PROD_URL: "https://www.fenixsk.sk",
      PROD_AUTH: "https://www.fenixsk.sk",
      PROD_ERP: "https://www.fenixsk.sk/Data",
      PROD_IMG: "https://www.fenixsk.sk",
      PROD_BANK: "https://moja.tatrabanka.sk/cgi-bin/e-commerce/start/cardpay",
      PROD_INDEX: "https://www.fenixsk.sk/meili",
      CONVERSION_API_URL:
        "https://graph.facebook.com/v20.0/" +
        META_PIXEL_ID +
        "/events?access_token=" +
        CONVERSION_API_TOKEN,
    };
  }
}
export const DEV_URL = createDEV_URL();

export const ERP_AUTH = "Basic ZXNob3A6ZUtjOXNhcHFaMUNGbWRKZnlCMDY=";

export const INDEX_TOKEN = {
  TOKEN: "2TJqi4UkmzBHmKEQUjJkOmwgufymu9dQ",
  API_KEY: "BfCxmnne319dtlO2NQy5RsLswG5cBOD95oT8VNevpVY=",
};

export const CAPTCHA = {
  SITEKEY: "0eb38f3a-d294-4e20-8b60-ffb03257bb1b",
  SECRET: "ES_f6d705d92bd34f469233b7acee37d1a3",
};

export const ERROR_MESSAGE = {
  INVALID_INPUT: "Invalid input!",
  INVALID_ACTIVATION_LINK: "Activation link is invalid!",
  USER_NOT_FOUND: "User does not exist",
  MULTIPLE_USER_MATCH: "Multiple user match",
  INVALID_RESET_LINK: "Reset link is invalid!",
  DEFAULT_ERROR_MESSAGE:
    "Vyskytla sa neočakavána chyba. Skúste to znova neskôr.",
  DEFAULT_CAPTCHA_ERROR:
    "Je nám to ľúto, ale nepodarilo sa nám Vás overiť. Skúste to znova.",

  AUTHENTICATION_ERROR: "Authentication credentials were not provided.",
};

export const CHECKBOX_MESSAGE = {
  INVOICE_EMAIL:
    "Tento mail slúži na posielanie faktúr. Ak sa ho rozhodnete zrušiť, faktúry budeme zasielať na registračný email.",
};

export const IMPORT_BASKET = {
  NOT_FOUND_PRODUCT:
    "Nepodarilo sa nájsť produkt so zadaným kódom. Prosím, odstránte tento produkt.",
};

export const PAYMENT_ERROR_MESSAGE = {
  EMPTY_PAYMENT_METHOD: "Vyberte platobnú metódu.",
};

export const DIALOG_MESSAGE = {
  CREDIT_EXCEEDED:
    "Prekročili ste povolený limit otvorených pohľadávok. Prosíme, uhraďte neuhradené faktúry. Do tej doby, budete mať možnosť iba zálohovej platby. V prípade otázok kontaktujte obchodné oddelenie.",
  SUCCESSFUL_OFFER:
    "Vašu požiadavku na cenovú ponuku sme prijali. Po vytvorení cenovej ponuky Vás budeme kontaktovať prostredníctom emailu. V prípade otázok kontaktujte obchodné oddelenie.",
  UNDEFINED_PRICE:
    "V košíku sa nachádza položka s nedefinovanou cenou. Ak chcete pokračovať požiadajte o cenovú ponuku, odstránte tento produkt alebo kontaktuje obchodné oddelenie.",
  QUANTiTY_ERROR:
    "V košíku sa nachádza položka s nesprávnym množstvom. Ak chcete pokračovať v objednávke, upravte množstvo.",
};

export const INPUT_FIELD_ERROR_MESSAGE = {
  EMPTY_INPUT: "Toto pole nemôže byť prázdne!",
  INVALID_INPUT: "Invalid input!",
  EMPTY_EMAIL: "Zadajte Email",
  EMPTY_FIRST_NAME: "Zadajte Meno",
  EMPTY_CODE_PRODUCT: "Zadajte kód produktu",
  EMPTY_LAST_NAME: "Zadajte Priezvisko",
  EMPTY_TELEPHONE: "Zadajte Telefón",
  EMPTY_COMPANY_NAME: "Zadajte Názov firmy",
  EMPTY_ICO: "Zadajte IČO",
  EMPTY_ZIP_CODE: "Zadajte PSČ",
  EMPTY_CITY: "Zadajte Mesto",
  EMPTY_ADDRESS: "Zadajte Adresu",
  EMPTY_COUNTRY: "Zadajte Štát",
  EMPTY_PASSWORD: "Zadajte Heslo",
  EMPTY_CONFIRM_PASSWORD: "Potvrďte heslo",
  EMPTY_CARDHOLDER: "Zadajte meno držiteľa karty",
  EMPTY_CUSTOMERTYPE: "Zadajte typ konta",
  PASSWORD_DOES_NOT_MATCH: "Heslá sa nezhodujú",
  AGREE_TERMS_DOES_NOT_CHECKED_SIGN_UP:
    "Pred registráciou musíte súhlasiť so spracovaním osobných údajov",
  AGREE_TERMS_DOES_NOT_CHECKED_CHECKOUT:
    "Pred dokončením objednávky musíte súhlasiť so spracovaním osobných údajov",
  INVOICE_EMAIL_DOES_NOT_CHECKED:
    "Podtvrďte email, na ktorý budeme zasielať faktúry.",
  ERROR_ICO: "Nesprávne IČO. Musí obsahovať 8 číslic",
  ERROR_ZIP_CODE: "Nesprávne PSČ. Musí obsahovať 5 číslic",
  ERROR_EMAIL: "Nesprávny Email",
  ERROR_FIRST_NAME:
    "Meno musí mať aspoň 3 znaky a nesmie obsahovať špeciálne znaky alebo čísla",
  ERROR_LAST_NAME:
    "Priezvisko musí mať aspoň 3 znaky a nesmie obsahovať špeciálne znaky alebo čísla",
  ERROR_TELEPHONE: "Nesprávne zadaný telefón. Zadajte v tvare +421 123456789",
  ERROR_COMPANY_NAME: "Názov firmy obsahuje nepovolené znaky",
  ERROR_CITY: "Mesto nesmie obsahovať špeciálne znaky alebo čísla",
  ERROR_ADDRESS: "Adresu obsahuje nepovolené znaky",
  ERROR_PASSWORD_LENGTH: "Heslo musí mať 8-20 znakov",
  ERROR_PASSWORD_STARTS: "Heslo musí začínať písmenom alebo číslom",
  ERROR_PASSWORD_SPECIAL_CHAR:
    "Heslo musí obsahovať aspoň 1 špeciálny znak, číslo, veľké a malé písmeno",
  ERROR_CARDHOLDER:
    "Meno držiteľa karty musí obsahovať 2-45 alfanumerickych znakov",
};

export const SIGNUP_NOTE_MESSAGE = {
  INVOICE_EMAIL_NOTE:
    "Ak je už vaša spoločnosť regitrovaná, použije sa email, ktorý už máme uložený.",
  FIRM_NAME_AND_ICO_NOTE:
    "Údaje o hľadanej spoločnosti budú automaticky vložené do registračného formulára z verejných registrov.",
};

export const SIGNUP_ERROR_MESSAGE = [
  {
    MESSAGE: "Required credentials should not be empty",
    DISPLAY_MESSAGE: "Povinné polia nesmú byť prázdne!",
  },
  {
    MESSAGE: "Please provide all required credentials",
    DISPLAY_MESSAGE: "Vyplnte všetky povinné polia!",
  },
  {
    MESSAGE: "User already exists",
    DISPLAY_MESSAGE: "Používateľ so zadanou e-mailovou adresou už existuje.",
  },
  {
    MESSAGE: "Cannot load company data",
    DISPLAY_MESSAGE:
      "Nemožno načítať firemné údaje. Skúste to znova neskôr alebo nás kontaktujte.",
  },
  {
    MESSAGE: "Company ico is not stored in our database!",
    DISPLAY_MESSAGE: "Neexistuje firma so zadaným IČO.",
  },
];

export const REMOVE_BASKET_MESSAGE = [
  {
    MESSAGE: "Items removed",
    DISPLAY_MESSAGE: "Produkty boli úspešne zmazané.",
  },
  {
    MESSAGE: "Items not found",
    DISPLAY_MESSAGE: "Tovar ste úspešne pridali do košíka.",
  },
  {
    MESSAGE: "User does not found",
    DISPLAY_MESSAGE: "Vyskytla sa neočakavána chyba. Skúste to znova neskôr.",
  },
];

export const INDEX_MESSAGE = [
  {
    MESSAGE: "Task successfully created",
    DISPLAY_MESSAGE: "Úloha pre index produktov bola úspešne vytvorená.",
  },
  {
    MESSAGE: "An error occured",
    DISPLAY_MESSAGE:
      "Pri získavaní produktov sa vyskytla chyba. Skúste to znova.",
  },
  {
    MESSAGE: "Unauthorized request",
    DISPLAY_MESSAGE: "Na vykonanie tejto akcie nemáte dostatočné práva.",
  },
];

export const IMPORT_BASKET_MESSAGE = [
  {
    MESSAGE: "Products found",
    DISPLAY_MESSAGE: "Tovar ste úspešne pridali do košíka.",
  },
  {
    MESSAGE: "Products found without quantity",
    DISPLAY_MESSAGE: "Tovar ste úspešne pridali do košíka.",
  },
  {
    MESSAGE: "Undefined product order",
    DISPLAY_MESSAGE:
      "Nie je možné pokračovať v objednávke. V košíku sa nachádza produkt, ktorý nevieme identifikovať. Odstránte tento produkt a pokračujte ďalej.",
  },
  {
    MESSAGE: "Undefined product offer",
    DISPLAY_MESSAGE:
      "Nie je možné požiadať o cenovú ponuku. V košíku sa nachádza produkt, ktorý nevieme identifikovať. Odstránte tento produkt a pokračujte ďalej.",
  },
  {
    MESSAGE: "No products selected",
    DISPLAY_MESSAGE: "Pre import produktov zadajte kódy produktov!",
  },
];

export const SIGNIN_ERROR_MESSAGE = [
  {
    MESSAGE: "Invalid Credentials",
    DISPLAY_MESSAGE: "Nesprávne prihlasovacie údaje! Zopakujte prihlásenie.",
  },
  {
    MESSAGE: "Please provide both email and password",
    DISPLAY_MESSAGE: "Zadajte e-mail a heslo!",
  },
  {
    MESSAGE: "Can not find firm info",
    DISPLAY_MESSAGE:
      "Nepodarilo sa načítať firemný cenník. (Zobrazí sa Hlavný cenník.)",
  },
];

export const ACTIVATION_EMAIL_MESSAGE = [
  {
    MESSAGE: "Email sent",
    DISPLAY_MESSAGE: "Aktivačný email odoslaný.",
  },
  {
    MESSAGE: "User activated",
    DISPLAY_MESSAGE: "Používateľ aktivovaný!",
  },
  {
    MESSAGE: "An error occurred while sending the email",
    DISPLAY_MESSAGE: "Pri odosielaní emailu sa vyskytla chyba.",
  },
  {
    MESSAGE: "Permission denied",
    DISPLAY_MESSAGE: "Na vykonanie operácie nemáte dostatočné práva.",
  },
];

export const INACTIVE_USER_ERROR_MESSAGE = [
  {
    MESSAGE: "Faild to retrieve inactive users",
    DISPLAY_MESSAGE: "Nepodarilo sa načítať neaktívnych používateľov.",
  },
];

export const ACCOUNT_UPDATE_MESSAGE = [
  {
    MESSAGE: "Successful update",
    DISPLAY_MESSAGE: "Váš profil bol úspešne aktualizovaný.",
  },
  {
    MESSAGE: "Invalid token.",
    DISPLAY_MESSAGE: "Vaša relácia vypršala. Pokračujte prihlásením.",
  },
];

export const SEND_ORDER_MESSAGE = [
  {
    MESSAGE: "Order should have rows",
    DISPLAY_MESSAGE: "Vaša objednávka neobsahuje žiaden tovar.",
  },
  {
    MESSAGE: "Send order failed",
    DISPLAY_MESSAGE:
      "Nepodarilo sa nám spracovať Vašu objednávku. Skúste ju zadať znova. Ak chyba pretrváva, kontaktujte obchodné oddelenie.",
  },
  {
    MESSAGE: "Invalid token.",
    DISPLAY_MESSAGE: "Vaša relácia vypršala. Pokračujte prihlásením.",
  },
];

export const SEND_OFFER_MESSAGE = [
  {
    MESSAGE: "Common error",
    DISPLAY_MESSAGE:
      "Nepodarilo sa nám spracovať Vašu cenovu ponuku. Skúste to znova. Ak chyba stále pretrváva kontaktujete obchodné oddelenie.",
  },
];

export const PAYMENT_GATEWAY_MESSAGE = [
  {
    MESSAGE: "Service unavailable.",
    DISPLAY_MESSAGE: "Nepodarilo sa nám spojiť s platobnou bránou.",
  },
];

export const AUTHENTICATE_MESSAGE = [
  {
    MESSAGE: "Invalid token.",
    DISPLAY_MESSAGE: "Vaša relácia vypršala. Pokračujte prihlásením.",
  },
];

export const PASSWORD_CHANGE_MESSAGE = [
  {
    MESSAGE: "Password changed",
    DISPLAY_MESSAGE: "Vaše heslo bolo úspešne zmenené.",
  },
  {
    MESSAGE: "Password does not match",
    DISPLAY_MESSAGE: "Zadané heslá sa nezhodujú!",
  },
  {
    MESSAGE: "Please provide password",
    DISPLAY_MESSAGE: "Zadajte heslo!",
  },
  {
    MESSAGE: "Invalid token.",
    DISPLAY_MESSAGE: "Vaša relácia vypršala. Pokračujte prihlásením.",
  },
];
export const RESET_PASSWORD_MESSAGE = [
  {
    MESSAGE: "Password reset",
    DISPLAY_MESSAGE: "Vaše heslo bolo úspešne zmenené.",
  },
  {
    MESSAGE: "Password does not match",
    DISPLAY_MESSAGE: "Zadané heslá sa nezhodujú!",
  },
  {
    MESSAGE: "Please provide password",
    DISPLAY_MESSAGE: "Zadajte heslo!",
  },
  {
    MESSAGE: "Reset link is invalid",
    DISPLAY_MESSAGE: "Odkaz na obnovenie hesla je neplatný!",
  },
];

export const FORGOTTEN_PASSWORD_MESSAGE = [
  {
    MESSAGE: "Please provide email",
    DISPLAY_MESSAGE: "Zadajte email!",
  },
  {
    MESSAGE: "User does not exist",
    DISPLAY_MESSAGE: "Nespravná emailová adresa!",
  },
  {
    MESSAGE: "Multiple user match",
    DISPLAY_MESSAGE: "Nespravná emailová adresa!",
  },
  {
    MESSAGE: "User found",
    DISPLAY_MESSAGE: "OK.",
  },
];

export const SIGNOUT_MESSAGE = [
  {
    MESSAGE: "Successfull logout",
    DISPLAY_MESSAGE: "Vaše odhlásenie prebehlo úspešne.",
  },
];

export const CAPTCHA_MESSAGE = [
  {
    MESSAGE: "Verification failed",
    DISPLAY_MESSAGE:
      "Nepodarilo sa nám overiť, že nie ste robot. Skúste to znova.",
  },
];

export const BASKET_ERROR_MESSAGE = [
  {
    MESSAGE: "Found an item with an undefined price",
    DISPLAY_MESSAGE:
      "V košíku sa nachádza položka s nedefinovanou cenou. Ak chcete pokračovať zmažte položku z košíka alebo kontaktuje obchodné oddelenie.",
  },
];

export const COOKIE_MESSAGE = {
  MESSAGE:
    "Na našej webovej stránke používame cookies na zlepšenie vášho používateľského zážitku a analýzu návštevnosti. Kliknutím na „Súhlasím“ vyjadrujete svoj súhlas s našou politikou cookies. Viac informácií nájdete v našich Zásadách používania cookies.",
};

export const COUNTRIES = [
  { label: "Česko" },
  { label: "Maďarsko" },
  { label: "Nemecko" },
  { label: "Poľsko" },
  { label: "Rakúsko" },
  { label: "Slovensko" },
];

export const USEFUL_LINKS = [
  { label: "O nás", route: "#aboutus" },
  { label: "Kontakt", route: "#contact" },
  { label: "Registrácia", route: "signup" },
  // TODO
  // { label: "Sitemap", route: "" },
];

export const FOOTER_LINKS = [
  { label: "Obchodné podmienky", route: "" },
  // TODO
  // { label: "Vrátenie tovaru", route: "" },
  // { label: "Reklamácie", route: "" },
  { label: "Ochrana osobných údajov", route: ROUTE_URLS.DATA_PRIVACY },
  { label: "Zásady používania cookies", route: ROUTE_URLS.COOKIE_POLICY },
];
export const PAGINATION = { displayAmount: 20 };

export const FINSTAT_CONFIG = {
  API_key: "5D63D472C10A43CFA4DCABB379C28B78",
  private_key: "BDF888F2735C41799F181EE238840AF2",
};

export const CHECKOUT_STEPS = ["Doručenie", "Platba", "Objednávka dokončená"];

export const ROLE_NAMES = {
  admin: "Administrátor",
  customer: "Zákazník",
};

export const MENU_GROUP_NAME = [
  "Automate",
  "Connect",
  "Mark, Assemble and Install",
  "Supply, Charge and Protect",
  "Switch, Measure and Monitor",
];
